import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageResponse } from '@/common/models/message-response.model';
import { ChangePasswordDTO, LoginRequestDTO, RegisterRequestDTO, User } from '@/pages/auth/models';
import {
  ChangeRecoveryPasswordDTO,
  CheckRecoveryPasswordDTO,
  RecoveryPasswordDTO
} from '@/pages/auth/models/recovery-password.model';
import { SocialLoginRequestDTO } from '@/pages/auth/models/social-login.model';
import { environment } from '@environments/environment';

const API_URL = `${environment.apiUrl}/user`;

@Injectable({ providedIn: 'root' })
export class AuthHttpService {
  constructor(private _http: HttpClient) {
  }

  login(loginDto: LoginRequestDTO): Observable<User> {
    return this._http.post<User>(`${API_URL}/auth-saas`, loginDto);
  }

  register(registerDTO: RegisterRequestDTO): Observable<User> {
    return this._http.post<User>(`${API_URL}/register-saas`, registerDTO);
  }

  recoveryPassword(registerDTO: RecoveryPasswordDTO): Observable<MessageResponse> {
    return this._http.post<MessageResponse>(`${API_URL}/recovery-password-saas`, registerDTO);
  }

  checkRecoveryPassword(registerDTO: CheckRecoveryPasswordDTO): Observable<MessageResponse> {
    return this._http.post<MessageResponse>(`${API_URL}/check-recovery-password`, registerDTO);
  }

  changeRecoveryPassword(registerDTO: ChangeRecoveryPasswordDTO): Observable<User> {
    return this._http.post<User>(`${API_URL}/change-recovery-password`, registerDTO);
  }

  getProfile(): Observable<User> {
    return this._http.get<User>(`${API_URL}/profile`);
  }

  changePassword(changePasswordDTO: ChangePasswordDTO): Observable<MessageResponse> {
    return this._http.post<MessageResponse>(`${API_URL}/change-password`, changePasswordDTO);
  }

  authGoogle(registerDTO: SocialLoginRequestDTO): Observable<User> {
    return this._http.post<User>(`${API_URL}/auth-google`, registerDTO);
  }

  authLinkedin(registerDTO: SocialLoginRequestDTO): Observable<User> {
    return this._http.post<User>(`${API_URL}/auth-linkedin`, registerDTO);
  }

  updateProfile(user: Partial<User>): Observable<User> {
    return this._http.put<User>(`${API_URL}/update-profile`, user);
  }
}
